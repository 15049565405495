import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { useAuth0 } from "@auth0/auth0-react"
import Layout from "../../components/Community/Layout"
import { useStore } from "../../utils/store"
import { client } from "../../sanity"
import { Flex, Label, Title, Box, Button } from "../../components/system"

import Video from "../../components/Video"
import StageQuestion from "../../components/Community/StageQuestion"

const template = [
  {
    id: 0,
    title: "1. Fashion Sketch",
    sub: "Your sketch needs to be a standard nine head system Fashion Sketch.",
    uploads: [],
  },
  {
    id: 1,
    title: "2. CAD Design / Technical Card",
    sub: "Upload Technical Card of the garment containing detailed description of style such as materials, fastening, defined stitches and more as necessary.",
    uploads: [],
  },
]

const StageOne = () => {
  const { user } = useAuth0()
  const [expert, setExpert] = useState()

  const [questions, setQuestions] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const uploads = useStore(state => state.uploads)
  const clearUploads = useStore(state => state.clearUploads)

  useEffect(() => {
    // TODO: CLEAR STORE UPOADS
    const query = '*[_type == "user" && uuid == $uuid]{assigned->{email}}'
    const params = { uuid: user.sub }

    client.fetch(query, params).then(data => {
      setExpert(data[0].assigned.email)
    })

    return () => clearUploads()
  }, [user])

  const submitStage = async () => {
    setSubmitting(true)
    await fetch("/.netlify/functions/submitStage", {
      method: "POST",
      body: JSON.stringify({
        name: user["https://ilana.uk/metadata"].name,
        title: `${user["https://ilana.uk/metadata"].name}`,
        user: user["https://ilana.uk/metadata"].id,
        number: 1,
        expert: expert,
        questions: uploads,
      }),
    })
      .then(res => res.json())
      .then(data => {
        navigate("/community/process")
        setSubmitting(false)
      })
      .catch(error => console.log(error))
  }

  const empty = uploads.length <= 1

  return (
    <Layout>
      <Flex flexDirection="column">
        <Label fontSize={1} color="faded">
          Fashion Sketch & CAD Design
        </Label>
        <Title fontSize={[6, 7]} color="secondary">
          Stage One
        </Title>
      </Flex>
      <Box px={[0, 6]} my={[3, 4]}>
        <Video url={`${process.env.GATSBY_URL}/videos/stage-one.mp4`} />
      </Box>
      <Flex flexDirection="column" alignItems="flex-start" p={[4, 5, 6]}>
        {template.map((item, key) => (
          <StageQuestion
            key={key}
            id={key}
            title={item.title}
            sub={item.sub}
            images={questions[key]?.uploads}
          />
        ))}

        {empty ? (
          <Box>
            <Button
              bg="black"
              color="white"
              hbg="#2d2d2d"
              hcolor="white"
              onClick={() =>
                alert("Please make sure you have completed all the questions")
              }
            >
              Submit Stage One ->
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              bg="black"
              color="white"
              hbg="#2d2d2d"
              hcolor="white"
              onClick={() => submitStage()}
              disabled={submitting}
            >
              {submitting ? "Please Wait..." : "Submit Stage One ->"}
            </Button>
          </Box>
        )}
      </Flex>
    </Layout>
  )
}

export default StageOne
